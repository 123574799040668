<template>
  <v-container>
    <v-card>
      <v-form
        ref="form"
      >
        <v-card-title class="text-h9">価格と割引率を入力してください</v-card-title>
          <v-col>
            <v-row class="justify-center">
              <v-card-text class="text-h2 pt-8 pb-8" align="center">¥{{ lpr_price }}</v-card-text>
            </v-row>
          </v-col>
          <v-col>
            <v-card-text>
              <v-text-field
                v-model="tru_price"
                label="売り場の表示金額"
                type="number"
                required
              >
              </v-text-field>
              <v-text-field
                v-model="price"
                label="ロープライスの金額"
                type="number"
                required
              >
              </v-text-field>
              <v-text-field
                v-model="ld_rate"
                label="リンクディール割引率(%)"
                type="number"
                required
              >
              </v-text-field>
            </v-card-text>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  リンクディール割引率計算
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card-text>
                    <v-text-field
                      v-model="disct_amot"
                      label="値引き額"
                      type="number"
                      required
                    >
                    </v-text-field>
                  </v-card-text>
                  <v-btn class="mb-3" block outlined @click="CalcDiscRate">割引率を反映</v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col>
            <v-btn class="mb-3" block outlined @click="CalcPrice">計算する</v-btn>
          </v-col>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'LprTaisaku',
  data: () => ({
    tru_price:4999,
    lpr_price:2857,
    price:2000,
    ld_rate:30,
    disct_amot:0,
  }),
  methods: {
    CalcPrice() {
      var rate = (100 - this.ld_rate) / 100;
      this.lpr_price = Math.round(this.price / rate);
    },
    CalcDiscRate() {
      var ld_price = this.tru_price - this.disct_amot;
      this.ld_rate = 100 - Math.round((ld_price / this.tru_price) * 100);
    }
  }
};
</script>

